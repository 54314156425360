import {ForloebStatusEnum} from "./ForloebStatusEnum";

const loadGoogleMapsApi = require('load-google-maps-api');
const configData = require('../../config.json');
import { Localization } from "./modules/localization-module";
import { NotificationModule } from "ditmer-embla";

interface Config {
    GoogleMapsApiKey: string;
}

const vist = Localization.getText("Vist");
const greenmapmarker = 'images/greenmapmarker.png';
const redmapmarker = 'images/redmapmarker.png';
const yellowmapmarker = 'images/yellowmapmarker.png';

const $parent = $("#forloeb-div");

//Til omflytning af divs ved omprioriteringNN
const googleApiKey: string = (configData as Config).GoogleMapsApiKey;
let vaerdiFoerValg: number = 0;
let favoritUnordered: boolean = false;
let erFavoritSide: boolean = false;
let erLedigSide: boolean = false;
let antalFavoritDivs: number = 0;
let markers = [] as any;
let filteredMarkers = [] as any; //Filtreret efter searchString o regioner for at optimere flytning af kort
let map: any;
let originalForloebOrder: HTMLElement[] = [];

const saveOriginalOrder = () => {
    originalForloebOrder = $parent.find(".enkelt-forloeb-div").toArray(); // Gem original sortering af forløb fra fanen 'Alle'
};

const defaultLat: number = 56.1957575;
const defaultLng: number = 11.6951955;
const defaultZoom: number = 7.0;

$(document).ready(function () {
    handleNotLoggedInModal();
    handleRundeSuspenderetModal();
    bindEvents();
    filterKeepOpen();

    loadGoogleMapsApi({
        key: googleApiKey
    }).then(function (googleMaps: any) {
        map = new googleMaps.Map(document.querySelector('#map'), {
            center: {
                lat: defaultLat,
                lng: defaultLng
            },
            panControl: false,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            rotateControl: false,
            fullscreenControl: true,
            zoom: defaultZoom
        });

        const icons = {
            ledig: {
                icon: greenmapmarker,
            },
            optaget: {
                icon: redmapmarker,
            },
            favorit: {
                icon: yellowmapmarker,
            }
        };

        let features = [] as any;

        let forloeb = $(".enkelt-forloeb-div");

        forloeb.each(function () {
            let lat = $(this).data("latitude");
            let lon = $(this).data("longitude");
            let header = $(this).data("infobox-header");
            let body = $(this).data("infobox-body");
            let searchable = $(this).data("searchable");
            let divId = $(this).data("id");
            let status = $(this).data("status");
            let favorit = $(this).data("favorit");
            let region = $(this).data("region");
            let speciale = $(this).data("speciale");
            let speciale2 = $(this).data("speciale2");
            let sygehus = $(this).data("sygehus");
            let sygehusnavn = $(this).data("sygehusnavn");
            let forloebid = $(this).data("forloebid");
            let tooltip = $(this).data("tooltip");
            let inaktiv = $(this).data("inaktiv");
            let collapseId = $(this).data("collapse-id");
            let btnId = $(this).data("clp-btn");
            if (lat !== undefined && lon !== undefined && divId !== undefined) {
                features.push({
                    position: new googleMaps.LatLng(lat, lon),
                    status: status,
                    favorit: favorit,
                    divId: divId,
                    header: header,
                    body: body,
                    tooltip: tooltip,
                    forloebid: forloebid,
                    inaktiv: inaktiv,
                    sygehusnavn: sygehusnavn,
                    region: region,
                    speciale: speciale,
                    speciale2: speciale2,
                    sygehus: sygehus, //TODO: SygehusId er empty-guid, overvej at undersøg hvis det bliver nødvendigt
                    searchable: searchable,
                    collapseId: collapseId,
                    btnId: btnId,
                });
            }
        });

        // Create markers.
        for (let i = 0; i < features.length; i++) {
            let icon = icons[features[i].status].icon;
            const forloebInSygehus = features.filter((x: any) => x.sygehusnavn === features[i].sygehusnavn && x.afdelingId === features[i].afdelingId);
            const anyLedige = forloebInSygehus.some((x:any) => x.status === ForloebStatusEnum.Ledig)

            const currentForloebLedig = features[i].status === ForloebStatusEnum.Ledig;
            const forloebErInaktivt = features[i].inaktiv === "True";

            if ((forloebErInaktivt || !anyLedige) && !erLedigSide)
                icon = icons.optaget.icon;
            else if (!forloebErInaktivt && currentForloebLedig && features[i].favorit == "True")
                icon = icons.favorit.icon;
            else
                icon = icons.ledig.icon;

            //Sæt marker
            let marker = new googleMaps.Marker({
                position: features[i].position,
                icon: icon,
                map: map,
                title: features[i].tooltip,
                collapseId: features[i].collapseId,
                divId: features[i].divId,
                header: features[i].header,
                body: features[i].body,
                forloebid: features[i].forloebid,
                searchable: features[i].searchable,
                status: features[i].status,
                favorit: features[i].favorit,
                region: features[i].region,
                speciale: features[i].speciale,
                speciale2: features[i].speciale2,
                sygehus: features[i].sygehus,
                btnId: features[i].btnId,
                infoWindow: new google.maps.InfoWindow({})
            });

            if (marker.favorit == "True" && currentForloebLedig)
                marker.setZIndex(110);
            else if (marker.status == ForloebStatusEnum.Ledig)
                marker.setZIndex(100);
            else
                marker.setZIndex(90);

            //Marker click event
            googleMaps.event.addListener(marker, "click", function () {
                let btn = $("#" + marker.btnId);
                let collapse = $("#" + marker.collapseId);
                let markerDiv = $("#" + marker.divId);
                map.setCenter(marker.getPosition());
                map.setZoom(12);
                openMarkerInfoWindow(map, marker, markers);

                if (markerDiv.hasClass("selected-in-map")) {
                    // already selected
                } else {
                    $(".selected-in-map").removeClass("selected-in-map");
                    markerDiv.addClass("selected-in-map");

                    $(".collapse").hide();
                    resetArrows();

                    turnArrow(btn);
                    let spaceFromDivToTop = markerDiv.offset().top;
                    let spaceToAdresseBar = $("#forloeb").offset().top + 41;
                    let currentScroll = $parent.scrollTop();
                    $parent.animate({ scrollTop: spaceFromDivToTop + currentScroll - spaceToAdresseBar }, "slow", function () {
                        collapse.slideToggle();
                    });
                }
            });

            markers.push(marker);
            filteredMarkers.push(marker);
        };

        $("#nulstilKort").on("click", function () {
            resetToDefaultZoomAndLatLng();
            resetFilters();
        });

        $("#visFaeroerne").on("click", function () {
            visFaeroerne();
            resetFilters();
        });

        //Når man zoomer/flytter billedet
        map.addListener('idle', function () {

            let bounds = map.getBounds();

            for (let i = 0; i < filteredMarkers.length; i++) {
                let div = $("#" + filteredMarkers[i].divId);

                if (erFavoritSide) {
                    div.removeClass("hidden");
                    continue;
                }

                if (bounds.contains(filteredMarkers[i].getPosition())) {
                    filteredMarkers[i].setMap(map);
                    div.addClass("shown");
                    div.removeClass("hidden");
                } else {
                    filteredMarkers[i].setMap(null);
                    div.addClass("hidden");
                    div.removeClass("shown");
                }
            }

            countBasisForloeb();
            if(!erLedigSide && !erFavoritSide)
                saveOriginalOrder();
        });

        $("#bekraeftforloeb-modal-wrapper").on("click", ".vaelg-forloeb", function (e) {
            e.stopPropagation();
            const forloebId = $(this).data("forloebid");
            const laegeId = $(this).data("laegeid");

            if (forloebId == "" || laegeId == "") {
                $('#not-logged-in-modal').modal('show');
            }
            else {
                showGodkendForloebModal(forloebId, laegeId);
            }
        });

        $("#vaelg-alle-region-checkboxes").on("click", function () {
            const checkboxes = $(".region-checkbox");
            let checkedCounter = 0;
            for (let i = 0; i < checkboxes.length; i++) {
                let thisCheckbox = checkboxes[i] as HTMLInputElement;
                if (thisCheckbox.checked == true)
                    checkedCounter++;
            }

            if (checkedCounter === checkboxes.length) {
                $(".region-checkbox").prop('checked', false);
                $(".region-checkbox").removeAttr('checked');
            } else {
                $(".region-checkbox").prop('checked', true);
                $(".region-checkbox").attr('checked', 'checked');
            }

            applyAllFilters(markers, map);
        });

        $(".region-checkbox").on("click", function () {

            if ($(this).attr('checked')) {
                $(this).prop('checked', false);
                $(this).removeAttr('checked');
            } else {
                $(this).prop('checked', true);
                $(this).attr('checked', 'checked');
            }

            applyAllFilters(markers, map);
        });

        $("#vaelg-alle-speciale-checkboxes").on("click", function () {
            const checkboxes = $(".speciale-checkbox");
            let checkedCounter = 0;
            for (let i = 0; i < checkboxes.length; i++) {
                let thisCheckbox = checkboxes[i] as HTMLInputElement;
                if (thisCheckbox.checked == true)
                    checkedCounter++;
            }

            if (checkedCounter === checkboxes.length) {
                $(".speciale-checkbox").prop('checked', false);
                $(".speciale-checkbox").removeAttr('checked');
            } else {
                $(".speciale-checkbox").prop('checked', true);
                $(".speciale-checkbox").attr('checked', 'checked');
            }

            applyAllFilters(markers, map);
        });

        $(".speciale-checkbox").on("click", function () {
            if ($(this).attr('checked')) {
                $(this).prop('checked', false);
                $(this).removeAttr('checked');
            } else {
                $(this).prop('checked', true);
                $(this).attr('checked', 'checked');
            }

            applyAllFilters(markers, map);
        });

        $("#vaelg-alle-sygehus-checkboxes").on("click", function () {
            const checkboxes = $(".sygehus-checkbox");
            let checkedCounter = 0;
            for (let i = 0; i < checkboxes.length; i++) {
                let thisCheckbox = checkboxes[i] as HTMLInputElement;
                if (thisCheckbox.checked == true)
                    checkedCounter++;
            }

            if (checkedCounter === checkboxes.length) {
                $(".sygehus-checkbox").prop('checked', false);
                $(".sygehus-checkbox").removeAttr('checked');
            } else {
                $(".sygehus-checkbox").prop('checked', true);
                $(".sygehus-checkbox").attr('checked', 'checked');
            }

            applyAllFilters(markers, map);
        });

        $(".sygehus-checkbox").on("click", function () {

            if ($(this).attr('checked')) {
                $(this).prop('checked', false);
                $(this).removeAttr('checked');
            } else {
                $(this).prop('checked', true);
                $(this).attr('checked', 'checked');
            }

            applyAllFilters(markers, map);
        });

        antalFavoritDivs = $(".flex-select").length;
        InitialiserFlexSelects();

        $(".gem-fjern-favorit-btn").on("click", function (e) {
            let $link = $(e.target);
            e.preventDefault();
            if (!$link.data('lockedAt') || +new Date() - $link.data('lockedAt') > 300) {
                let juridiskAftaleFremvist = $("#LaegeFremvistForJuridiskAftale");

                if (juridiskAftaleFremvist.val() !== "True") {
                    $("#juridisk-aftale-modal").modal('show');
                    juridiskAftaleFremvist.val("True");
                    let juridiskAftaleUrl = $("#JuridiskAftaleFremvist").val() as string;
                    let rundeLaegeId = $("#RundeLaegeId").val();
                    juridiskAftaleUrl = juridiskAftaleUrl + "?rundeLaegeId=" + rundeLaegeId;
                    const jurParameters = JSON.stringify({ 'rundeLaegeId': rundeLaegeId });

                    $.ajax({
                        contentType: "application/json; charset=utf-8",
                        dataType: "json",
                        data: jurParameters,
                        type: "POST",
                        url: juridiskAftaleUrl
                    });
                }

                let forloebId = $(this).data("forloebid");
                let url = $(this).data("url");

                const parameters = JSON.stringify({ 'forloebId': forloebId });

                $(".gem-fjern-favorit-btn").prop("disabled", true);

                $.ajax({
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    type: "POST",
                    url: url,
                    data: parameters,
                    success: (response) => {
                        for (let i = 0; i < markers.length; i++) {
                            if (markers[i].forloebid === forloebId) {
                                let badgefavorit = $("#badgedivFavorit-" + forloebId.toLowerCase());

                                if (markers[i].favorit !== 'True') {
                                    antalFavoritDivs++;
                                    HaandterStylingOgHtmlForFavoritGemt(markers[i], badgefavorit, $(this));
                                    HaandterPrioriteterEfterTilfoejetFavorit();
                                    TilfoejSelect2TilFavoritDiv(forloebId); //(Skal være efter HaandterPrioriteterEfterTilfoejetFavorit())

                                    $(this).data("url", response.responseText); //Sæt url'en til at gemme/fjerne favorit
                                    NotificationModule.showSuccessSmall("Forløb blev gemt som favorit!");
                                    favoritUnordered = true;
                                } else if (markers[i].favorit === 'True') {
                                    antalFavoritDivs--;

                                    HaandterStylingOgHtmlForFavoritFjernet(markers[i], badgefavorit, $(this), forloebId);

                                    $(this).data("url", response.responseText); //Sæt url'en til at gemme/fjerne favorit
                                    let prio = $("#flex-select-" + forloebId);
                                    omrokerPrioriteterEfterFjernetFavorit(prio.val());
                                    NotificationModule.showSuccessSmall("Forløb blev fjernet som favorit!");
                                }
                            }
                        }

                        $(".gem-fjern-favorit-btn").prop("disabled", false);
                    },
                    error: (response) => {
                        $(".gem-fjern-favorit-btn").prop("disabled", false);
                    }
                });
            }

            $link.data('lockedAt', +new Date());
        });

        $('#SearchQuery').keyup(delay(function () {
            applyAllFilters(markers, map);
        }, 680)); //Tid før der søges efter man har tastet en knap

        $(".filter-button").on("click", function () {
            $(".filter-button").removeClass("active");
            $(this).addClass("active");

            applyAllFilters(markers, map);
        });

        $(".locationBtn").on("click", function () {
            let forloebId = $(this).data("forloebid");

            for (let i = 0; i < markers.length; i++) {
                if (markers[i].forloebid === forloebId) {
                    map.panTo(markers[i].getPosition());
                    if (map.zoom !== 9)
                        map.setZoom(9);
                    openMarkerInfoWindow(map, markers[i], markers);
                }
            }
        });

        ////Clustering
        //var markerCluster = new MarkerClusterer(map, markers,
        //    { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });


    }).catch(function (error: any) {
        console.error(error)
    });

    $(".collapseBtn").on("click", function () {
        turnArrow($(this));
        let target = $(this).data("target");
        $("#" + target).slideToggle();
    });

    $(".info-button").off().on("click", function () {

        var url = $(this).data("url");

        $.get(url, function (data: any) {
            $('#forloeb-info-modal-wrapper').html(data);

            $('#forloeb-info-modal').modal('show');
        });
    });


    $(".vaelg-forloeb").off().on("click", function (e) {
        e.stopPropagation();
        const forloebId = $(this).data("forloebid");
        const laegeId = $(this).data("laegeid");

        if (forloebId == "" || laegeId == "") {
            $('#not-logged-in-modal').modal('show');
        }
        else {
            showGodkendForloebModal(forloebId, laegeId);
        }
    });
});

type forloebSortModel = {
    status: string;
    favoritBadgeVisible: boolean;
    prioritering: number;
    newPrioritering: number;
}

const sortForloeb = (erFavoritSide: boolean, erLedigSide: boolean) => {
    if(erFavoritSide) {
        const forloebDivs = $parent.find(".enkelt-forloeb-div").toArray();
        const sortedForloebDivs = forloebDivs.sort((a, b) => {
            const $a = $(a), $b = $(b);

            const aForloebSortModel: forloebSortModel = {
                status: $a.data("status"),
                favoritBadgeVisible: ($("#badgedivFavorit-" + ($a.data("forloebid")).toLowerCase())).is(':visible'),
                prioritering: $a.data("prioritering"),
                newPrioritering: +$("#select2-" + ($a.find(".flex-select")).data("select2-id") + "-container").text()
            }
            const bForloebSortModel: forloebSortModel = {
                status: $b.data("status"),
                favoritBadgeVisible: ($("#badgedivFavorit-" + ($b.data("forloebid")).toLowerCase())).is(':visible'),
                prioritering: $b.data("prioritering"),
                newPrioritering: (+$("#select2-" + ($b.find(".flex-select")).data("select2-id") + "-container").text())
            }

            // Sort "ledig" at the top
            if (aForloebSortModel.status !== bForloebSortModel.status) {
                return aForloebSortModel.status === ForloebStatusEnum.Ledig ? -1 : 1;
            }

            // Sort favoritter below non-favoritter
            if (aForloebSortModel.favoritBadgeVisible !== bForloebSortModel.favoritBadgeVisible) {
                return aForloebSortModel.favoritBadgeVisible ? 1 : -1; // Sort favorit lower than non-favorit
            }

            // Sort by prioritering
            // Handle if new prioritering is selected
            if (erFavoritSide &&
                (
                    (!!aForloebSortModel.newPrioritering && aForloebSortModel.prioritering !== aForloebSortModel.newPrioritering) ||
                    (!!bForloebSortModel.newPrioritering && bForloebSortModel.prioritering !== bForloebSortModel.newPrioritering)
                ))
                return aForloebSortModel.newPrioritering - bForloebSortModel.newPrioritering;
            else {
                return aForloebSortModel.prioritering - bForloebSortModel.prioritering;
            }
        });
        $parent.append(sortedForloebDivs);
    } else if (erLedigSide) { // Under 'Ledige' fanen skal den originale forløb sortering gælde
        const filteredForloebDivs = originalForloebOrder;
        filteredForloebDivs.filter(s => {
            const $s = $(s);
            return $s.data("status") !== ForloebStatusEnum.Ledig; // behold kun elementer med status 'ledig'
        });
        $parent.append(filteredForloebDivs);
    } else {
        $parent.append(originalForloebOrder); // Hvis brugeren genbesøger 'Alle' fanen skal den originale forløb sortering gælde
    }
}

const HaandterStylingOgHtmlForFavoritGemt = (marker: any, badge: any, button: any) => {
    marker.favorit = 'True';
    badge.show();
    marker.setIcon(yellowmapmarker);
    button.html('<svg class="embla-icon icon-left" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
        '<use xlink: href = "/dist/icons/sprite.symbol.svg#star-filled" > </use>' +
        '</svg> Fjern fra favoritter')
}

const HaandterStylingOgHtmlForFavoritFjernet = (marker: any, badge: any, button: any, forloebId: any) => {
    marker.favorit = 'False';
    badge.hide();
    marker.setIcon(greenmapmarker);
    button.html('<svg class="embla-icon icon-left" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
        '<use xlink: href = "/dist/icons/sprite.symbol.svg#star" > </use>' +
        '</svg> Gem til favoritter')

    $(".flex-1").each(function () {
        if ($(this).data("forloebid") == forloebId)
            $(this).addClass("hidden");
    });
}

const HaandterPrioriteterEfterTilfoejetFavorit = () => {
    $(".flex-select").each(function () {
        let optionsLength = $(this).find("option").length;
        if (+optionsLength < +(antalFavoritDivs)) {
            $('<option value="' + (optionsLength + 1) + '">' + (optionsLength + 1) + '</option>').appendTo(this);
        }
    });

    sortForloeb(erFavoritSide, erLedigSide);
}

const omrokerPrioriteterEfterFjernetFavorit = (prioritet: any) => {
    $(".flex-select").each(function () {
        let currVal = $(this).val() as number;
        let optionsLength = $(this).find("option").length;
        $(this).find("option[value=" + optionsLength + "]").remove();
        if (+currVal > +prioritet) {
            $(this).val(--currVal);
            let containerMedVistTal = $("#select2-" + $(this).data("select2-id") + "-container");
            containerMedVistTal.text(currVal);
            containerMedVistTal.prop("title", (currVal));
        }
    })

    sortForloeb(erFavoritSide, erLedigSide);
}

const visFaeroerne = () => {
    map.setZoom(9);
    const center = new google.maps.LatLng(61.9610879, -7.0022263);
    map.setCenter(center);
};

const resetToDefaultZoomAndLatLng = () => {
    map.setZoom(defaultZoom);
    const center = new google.maps.LatLng(defaultLat, defaultLng);
    map.setCenter(center);
};

const resetFilters = () => {
    $("#SearchQuery").val("");
    $(".region-checkbox").prop("checked", true);
    $(".speciale-checkbox").prop("checked", true);
    $(".sygehus-checkbox").prop("checked", true);
    $(".alle-filter").addClass("active");
    $(".ledig-filter").removeClass("active");
    $(".favorit-filter").removeClass("active");
    applyAllFilters(markers, map);
};

const applyAllFilters = (markers: any, map: any) => {
    let regions = $(".region-checkbox").map(function () {
        if ($(this).prop("checked") == true)
            return $(this).data("politisk-region-id") as number;
    });
    let specialer = $(".speciale-checkbox").map(function () {
        if ($(this).prop("checked") == true)
            return $(this).data("speciale-id") as number;
    });
    let sygehuse = $(".sygehus-checkbox").map(function () {
        if ($(this).prop("checked") == true)
            return $(this).data("sygehus-id") as number;
    });

    let filter = ($("#SearchQuery").val() as string).toLowerCase();
    let filterSplitByWhitespace = filter.split(' ') as [];
    let applySearchFilter = (+filter.length > +0);
    let applyRegionsFilter = (+regions.length != +6);
    let applySpecialeFilter = (+specialer.length != +6);
    let applySygehuseFilter = (+sygehuse.length != +6);
    let alleFilter = $(".alle-filter").hasClass("active");
    let ledigFilter = $(".ledig-filter").hasClass("active");
    let favoritFilter = $(".favorit-filter").hasClass("active");

    if (alleFilter) {
        erFavoritSide = false;
        erLedigSide = false;
        $(".flex-1").addClass("hidden");
    } else if (ledigFilter) {
        erFavoritSide = false;
        erLedigSide = true;
        $(".flex-1").addClass("hidden");
    } else if (favoritFilter) {
        erFavoritSide = true;
        erLedigSide = false;
        $(".flex-1").removeClass("hidden");
        if (favoritUnordered)
            orderFavoritDivsBySelectVal();

        resetToDefaultZoomAndLatLng();
    }
    sortForloeb(erFavoritSide, erLedigSide);

    filteredMarkers = [] as any;

    let bounds = map.getBounds();

    for (let i = 0; i < markers.length; i++) {
        let div = $("#" + markers[i].divId);

        let containsRegion = false;

        if (applyRegionsFilter) {
            for (let j = 0; j < regions.length; j++) {
                if (regions[j] === markers[i].region)
                    containsRegion = true;
            }
        }

        let containsSpeciale = false;

        if (applySpecialeFilter) {
            for (let j = 0; j < specialer.length; j++) {
                if (specialer[j] === markers[i].speciale || specialer[j] === markers[i].speciale2)
                    containsSpeciale = true;
            }
        }

        let containsSygehus = false;

        if (applySygehuseFilter) {
            for (let j = 0; j < sygehuse.length; j++) {
                if (sygehuse[j] === markers[i].sygehus)
                    containsSygehus = true;
            }
        }


        let containsBtnFilter = false;
        if (alleFilter) {
            containsBtnFilter = true;
        } else if (ledigFilter) {
            containsBtnFilter = markers[i].status == ForloebStatusEnum.Ledig;
        } else if (favoritFilter) {
            containsBtnFilter = markers[i].favorit == 'True';
        }

        let erPaaMap = true;

        if (applySearchFilter) {
            let etAfOrdeneMatcherIkke = false

            for (let k = 0; k < +filterSplitByWhitespace.length; k++) {
                if (!(markers[i].searchable.toLowerCase().indexOf(filterSplitByWhitespace[k]) >= 0))
                    etAfOrdeneMatcherIkke = true;
            }

            if (etAfOrdeneMatcherIkke)
                erPaaMap = false;
        }

        if (applyRegionsFilter) {
            if (!containsRegion)
                erPaaMap = false;
        }

        if (applySpecialeFilter) {
            if (!containsSpeciale)
                erPaaMap = false;
        }

        if (applySygehuseFilter) {
            if (!containsSygehus)
                erPaaMap = false;
        }

        if ((!containsBtnFilter))
            erPaaMap = false;

        if (erPaaMap) { //Hvis filter er indeholdt
            filteredMarkers.push(markers[i]);
            markers[i].setMap(map);
            div.addClass("shown");
            div.removeClass("hidden");
        } else {
            markers[i].setMap(null);
            div.addClass("hidden");
            div.removeClass("shown");
        }
    }

    for (let i = 0; i < filteredMarkers.length; i++) {
        let div = $("#" + filteredMarkers[i].divId);

        if (erFavoritSide) {
            div.removeClass("hidden");
            continue;
        }

        if (!bounds.contains(filteredMarkers[i].getPosition())) {
            filteredMarkers[i].setMap(null);
            div.addClass("hidden");
            div.removeClass("shown");
        }
    }

    countBasisForloeb();
}

const openMarkerInfoWindow = (map: any, marker: any, markers: any) => {

    for (let i = 0; i < markers.length; i++) {
        markers[i].infoWindow.close();
    }
    marker.infoWindow.setOptions({ disableAutoPan: true });
    marker.infoWindow.setContent('<div id="content">' +
        '<div id="siteNotice">' +
        "</div>" +
        '<h6 class="firstHeading subtle text-blue">' + marker.header + '</h6>' +
        '<div id="bodyContent">' + marker.body +
        "</div>" +
        "</div>");
    marker.infoWindow.open(map, marker);
}


const countBasisForloeb = () => {
    let header = $("#vises-aktuelt");
    let forloebDivs = $(".enkelt-forloeb-div");

    let counter = 0;

    for (let i = 0; i < forloebDivs.length; i++) {
        if ($(forloebDivs[i]).hasClass("shown"))
            counter++;
    }

    header.text("(" + counter + " " + vist + ")")
}


const handleNotLoggedInModal = () => {
    const showNotLoggedInModal = $('#ShowNotLoggedInModal').val();

    if (showNotLoggedInModal === "True") {
        $('#not-logged-in-modal').modal('show');
    }
};

const handleRundeSuspenderetModal = () => {
    const showSuspenderetModal = $('#ShowSuspenderetModal').val();

    if (showSuspenderetModal === "True") {
        $('#suspenderet-modal').modal('show');
    }
};

const turnArrow = (c: any) => {
    let shownChild = $(c).children(".shown");
    let hiddenChild = $(c).children(".hidden");

    shownChild.addClass("hidden");
    shownChild.removeClass("shown");
    hiddenChild.addClass("shown");
    hiddenChild.removeClass("hidden");
};

function delay(callback: any, ms: any) {
    let timer = 0;
    return function () {
        const context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
};

const resetArrows = () => {
    $(".arrow-up").addClass("hidden");
    $(".arrow-up").removeClass("shown");
    $(".arrow-down").addClass("shown");
    $(".arrow-down").removeClass("hidden");
};

const orderFavoritDivsBySelectVal = () => {
    $(".flex-select").each(function () {
        let thisVal = $(this).val() as any;
        let nuvaerendeDiv = $("#enkelt-forloeb-div-" + $(this).data("counter"));
        $(".flex-select").each(function () {
            let innerNuvaerendeDiv = $("#enkelt-forloeb-div-" + $(this).data("counter"));
            if ($(this).val() == (thisVal + 1)) {
                nuvaerendeDiv.insertBefore(innerNuvaerendeDiv);
            } else if ($(this).val() == (thisVal - 1)) {
                nuvaerendeDiv.insertAfter(innerNuvaerendeDiv);
            }
        })
    });
    favoritUnordered = false;
};


const TilfoejSelect2TilFavoritDiv = (forloebId: any) => {
    $(".flex-1").each(function () {
        if ($(this).data("forloebid") == forloebId) {

            let antalFavoritter = $(".flex-select").length;

            if ($(this).hasClass("selDiv")) {
                if (+$(this).children().length == +0) {
                    $(this).append('<select id="flex-select-' + forloebId + '" data-forloebid="' + forloebId + '" data-counter="' + $(this).data("counter") + '" data-selectprioritering="' + (antalFavoritter + 1) + '" class="flex-select prioritering-dropdown"></select>')
                    let thisSelect = $("#flex-select-" + forloebId);
                    thisSelect.select2();

                    for (let n = 1; n <= (antalFavoritter + 1); n++) {
                        const newOption = new Option(n.toString(), n.toString(), false, (n == antalFavoritter + 1));
                        thisSelect.append(newOption).trigger('change');
                    }
                    thisSelect.val(antalFavoritter + 1);
                } else {
                    let select = $(this).children(".flex-select");
                    if (+select.find("option").length < +antalFavoritter) {
                        const newOption = new Option((antalFavoritter).toString(), (antalFavoritter).toString(), false, true);
                        select.append(newOption).trigger('change');
                    }
                    select.val(antalFavoritter);
                }

            }
            if (erFavoritSide) {
                $(this).removeClass("hidden");
            }

            //Style som embla select
            $(".select2-selection__arrow").html('<svg class="embla-icon dropdown-arrow" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg"><use xlink:href="/dist/icons/sprite.symbol.svg#arrow-down"></use></svg>');
            $(".select2-container--default").css('width', '100%');
        }
    })
    $(".flex-select").unbind("select2:opening");
    $(".flex-select").unbind("change");
    InitialiserFlexSelects();
};

const laasPrioriteringsdropdowns = () => {
    $(".prioritering-dropdown").prop("disabled", true);
};

const aabnPrioriteringsdropdowns = () => {
    $(".prioritering-dropdown").prop("disabled", false);
};

const InitialiserFlexSelects = () => {
    $(".flex-select").on("select2:opening", function () {
        vaerdiFoerValg = $(this).val() as number;
    });

    $(".flex-select").on("change", function () {
        let newVal = $(this).val() as number;
        if (newVal != vaerdiFoerValg) {
            let divMedValgtPrioritering = $();
            let nuvaerendeDiv = $("#enkelt-forloeb-div-" + $(this).data("counter"));
            let allSelects = $(".flex-select");
            let forloebId = $(this).data("forloebid");
            const parameters = JSON.stringify({ 'forloebId': forloebId, 'nyPrioritering': newVal });
            const url = "Runde/OmprioriterForloeb?forloebId=" + forloebId + "&nyPrioritering=" + newVal;

            // Lås felter
            laasPrioriteringsdropdowns();

            $.ajax({
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                type: "POST",
                url: url,
                data: parameters,
                error: () => {
                    NotificationModule.showErrorSmall("Noget fejlede, prøv at genopfriske siden eller prøv igen senere!");
                    // Lås felter op igen
                    aabnPrioriteringsdropdowns();
                },
                success: () => {
                    NotificationModule.showSuccessSmall("Forløb flyttet fra prioritet " + vaerdiFoerValg + " til " + newVal);

                    allSelects.not(this).each(function () {
                        let currData = $(this).val();
                        if (currData == newVal) {
                            divMedValgtPrioritering = $("#enkelt-forloeb-div-" + $(this).data("counter"));
                        }
                    });

                    allSelects.not(this).each(function () {
                        let currVal = $(this).val() as number;
                        let containerMedVistTal = $("#select2-" + $(this).data("select2-id") + "-container");
                        //Hvis valgt større værdi
                        if (+newVal > +vaerdiFoerValg) {
                            //Hvis det nuværende element også havde større værdi eller lig
                            if (+currVal <= +newVal && +currVal > +vaerdiFoerValg) {
                                $(this).val(--currVal);
                            }
                        } else if (+newVal < +vaerdiFoerValg) {
                            //Hvis det nuværende element har mindre værdi eller lig det valgte
                            if (+currVal >= +newVal && +currVal < +vaerdiFoerValg) {
                                $(this).val(++currVal);
                            }
                        }
                        //Opdater teksten i dropdown
                        containerMedVistTal.text(currVal);
                        containerMedVistTal.prop("title", (currVal));

                    });

                    let currentContainer = $("#select2-" + $(this).data("select2-id") + "-container");
                    currentContainer.text(newVal);
                    currentContainer.prop("title", (newVal));
                    $(this).val(newVal);

                    if (+newVal > +vaerdiFoerValg)
                        nuvaerendeDiv.insertAfter(divMedValgtPrioritering);
                    else if (+newVal < +vaerdiFoerValg)
                        nuvaerendeDiv.insertBefore(divMedValgtPrioritering);

                    sortForloeb(erFavoritSide, erLedigSide);

                    // lås felter op igen
                    aabnPrioriteringsdropdowns();
                }
            });
        }
    });
};

export const showGodkendForloebModal = (forloebId: string, laegeId: string) => {
    const godkendForloebModalWrapperSelector = "#godkendforloeb-modal-wrapper";
    const godkendForloebModalSelector = "#godkendforloeb-modal";
    const godkendForloebUrl = $(godkendForloebModalWrapperSelector).data("url");

    $.ajax({
        url: godkendForloebUrl,
        data: {
            forloebId,
            laegeId
        },
        type: "POST",
        success: (data: any) => {
            if (data.errorMessage.length > 0) {
                NotificationModule.showError(Localization.getText("Fejlside_Ups"), data.errorMessage);
                return;
            }

            $(godkendForloebModalWrapperSelector).html(data.html);
            $(godkendForloebModalSelector).modal("show");
        }
    });
};

const bindEvents = () => {
    bindGodkendForloebEvents();
    bindBekraeftForloebEvents();
};

const filterKeepOpen = () => {
    $('.dropdown-toggle').on("click", function(event) {
        $(".dropdown-menu").slideUp("fast");
        $(this).closest(".button-wrapper").find(".dropdown-menu").slideToggle("fast");
        event.stopPropagation();
    });

    $('.dropdown-menu').on("click", function(event) {
        event.stopPropagation();
    });

    $(window).on("click", function() {
        $('.dropdown-menu').slideUp("fast");
    });
};

const bindBekraeftForloebEvents = () => {
    const bekraeftForloebModalWrapperSelector = "#bekraeftforloeb-modal-wrapper";
    const bekraeftForloebModalSelector = "#godkendforloeb-modal";
    const aftaleLinkSelector = "#aftale-link";
    const aftaleLoaderSelector = ".aftale-loader";

    $(bekraeftForloebModalWrapperSelector).on('shown.bs.modal', function (e) {
        const tryIsAftaleGeneratedUrl = $(aftaleLinkSelector).data("url");
        const downloadAftaleUrl = $(aftaleLinkSelector).data("downloadurl");
        const downloadAftale = Localization.getText("LinkTilDownloadAfAnsaettelsesOgUddannelsesaftale");
        const linkTilAftaleSendesPaaEmail = Localization.getText("LinktilAftaleSendesPaaEmail");
        const duVilModtageEmailMedLinkTilAftale = Localization.getText("DuVilModtageEmailMedLinkTilAftale");

        $.ajax({
            url: tryIsAftaleGeneratedUrl,
            type: "POST",
            success: (data: boolean) => {
                if (data === true) {
                    $(aftaleLinkSelector).html("<a href=" + downloadAftaleUrl + ">" + downloadAftale + "</a><p>" + duVilModtageEmailMedLinkTilAftale + "</p>");
                } else {
                    $(aftaleLinkSelector).html(linkTilAftaleSendesPaaEmail);
                }

                $(aftaleLoaderSelector).hide();
            },
            error: (jqXHR, textStatus) => {
                $(aftaleLinkSelector).html(linkTilAftaleSendesPaaEmail);
                $(aftaleLoaderSelector).hide();
            }
        });
    })
}

const bindGodkendForloebEvents = () => {
    const godkendForloebModalWrapperSelector = "#godkendforloeb-modal-wrapper";
    const godkendForloebModalSelector = "#godkendforloeb-modal";

    $(godkendForloebModalWrapperSelector).on('shown.bs.modal', function (e) {
        $("#accepter-btn").on("click", function (e) {
            e.stopPropagation();
            const forloebId = $(this).data("forloebid");
            const regionId = $(this).data("regionid");
            const laegeId = $(this).data("laegeid");

            if (forloebId == "" || laegeId == "") {
                $('#not-logged-in-modal').modal('show');
            } else {
                $(godkendForloebModalSelector).modal('hide');
                showBekraeftForloebModal(forloebId, regionId, laegeId);
            }
        });
    })
}

export const showBekraeftForloebModal = (forloebId: string, regionId: string, laegeId: string) => {
    const bekraeftForloebModalSelector = "#bekraeftforloeb-modal";
    const bekraeftForloebModalWrapperSelector = "#bekraeftforloeb-modal-wrapper";
    const bekraeftForloebUrl = $(bekraeftForloebModalWrapperSelector).data("url");

    $.ajax({
        url: bekraeftForloebUrl,
        data: {
            forloebId,
            regionId,
            laegeId
        },
        type: "POST",
        success: (data: string) => {
            $(bekraeftForloebModalWrapperSelector).html(data);
            $(bekraeftForloebModalSelector).modal("show");
        }
    });
};
